import React from 'react'
import { Link } from '@reach/router'
import { format } from 'date-fns'
import { Flex, Badge } from 'pcln-design-system'

import useAppState from '../utils/useAppState'

export default function Prayer ({ prayerId }) {
  const { getPrayer, currentUser } = useAppState()
  const prayer = getPrayer(prayerId)

  if (!prayer) return null

  const { id, title, description, createdDate, isPrivate, isPinned, isClosed, uid } = prayer
  return (
    <section>
      <form>
        <h3>{title}</h3>
        <span>{format(new Date(createdDate), 'MMM D, YYYY')}</span>
        <br />
        { currentUser && currentUser.uid == uid && <Link to={`/edit/${id}`}>Edit</Link> }
        <p>
          {description}
        </p>
        <Flex style={{width: '100%'}} justifyContent='flex-end' m={2}>
          {isClosed && <Badge bg='red' mr={2}>closed</Badge>}
          {isPrivate && <Badge bg='lightRed' mr={2}>private</Badge>}
          {isPinned && <Badge bg='lightBlue' mr={2}>pinned</Badge>}
        </Flex>

        <Link to="/">{'<- '}Home</Link>
      </form>
    </section>
  )
}