import React, { useRef, useState, useEffect } from 'react'
import { Link, navigate } from '@reach/router'
import { format } from 'date-fns'
import { useFormState } from 'react-use-form-state'
import isEmpty from 'lodash/isEmpty'
import { Button } from 'pcln-design-system'

import useAppState from '../utils/useAppState'

export default function Prayer({ prayerId }) {
  const { getPrayer, editPrayer, currentUser } = useAppState()
  const [localPrayer, setLocalPrayer ] = useState(getPrayer(prayerId))


  if (!localPrayer) return null
  console.log('EDIT UID', currentUser && currentUser.uid)

  const { title, description, isPinned, isPrivate, isClosed, uid } = localPrayer

  const meta = []
  if (isPinned) {
    meta.push('isPinned')
  }

  if (isPrivate) {
    meta.push('isPrivate')
  }

  if (isClosed) {
    meta.push('isClosed')
  }
  const [formState, { text, textarea, checkbox }] = useFormState({
    title,
    description,
    meta
  })

  function handleSubmit(e) {
    e.preventDefault()
    if (isEmpty(formState.errors)) {
      editPrayer(prayerId, formState.values)
      navigate('/')
    }
  }

  return (
    <section>
      <form onSubmit={handleSubmit}>
        <h3>EditPrayer Request</h3>
        {/* <input type="hidden" name="createdDate" value={Date.now()} /> */}

        <div className="fieldset">
          <label htmlFor="title">Title</label>
          <input {...text('title')} />
        </div>


        <div className="fieldset">
          <label htmlFor="description">
            <span>Description:</span>
          </label>
          <textarea {...textarea('description')} />
        </div>

        <p>
          <label htmlFor="isPublic">
            <input {...checkbox('meta', 'isPrivate')} />
            <span>is private?</span>
          </label>

          <br />
          <label htmlFor="isPinned">
            <input {...checkbox('meta', 'isPinned')} />
            <span>is pinned?</span>
          </label>

          <br />
          <label htmlFor="isClosed">
            <input {...checkbox('meta', 'isClosed')} />
            <span>is closed?</span>
          </label>
        </p>
        <Button size="medium" type="submit">Submit</Button>
      </form>

      <p>
        { currentUser && currentUser.displayName }
      </p>

      <Link to="/">{'<- '}Home</Link>
    </section>
  )
}