import React, { useEffect, useState } from 'react'
import { Link } from '@reach/router'
import { Text, Flex } from 'pcln-design-system'
import styled from 'styled-components'

import useAppState from '../utils/useAppState'
import PrayerList from '../components/PrayerList'

const BlockLink = styled(Link)`
  padding: 16px;
  border: 1px solid lightblue;
  margin: 8px 0;
`

const Home = () => {
  const [publicPrayers = [], setPublicPrayers] = useState([])
  const { currentUser, getFilteredPrayers, signIn } = useAppState()

  useEffect(() => {
    getFilteredPrayers('public').then(setPublicPrayers)
  }, [publicPrayers.length])
  
  return (
    <Flex flexDirection='column'>
      <BlockLink to="/prayerlist/public">public prayers</BlockLink>
      {
        currentUser ? (
          <>
            <BlockLink to="/prayerlist/user">my prayers</BlockLink>
            <BlockLink to="/prayerlist/private">my private prayers</BlockLink>
            <BlockLink to="/prayerlist/pinned">pinned prayers</BlockLink>
            <BlockLink to="/prayerlist/closed">closed prayers</BlockLink>
          </>
        ) :
        <Text><a onClick={signIn}>Sign In</a> for more options</Text>
      }
      
      <section>
        <h3>Public Prayers</h3>
        <PrayerList prayers={publicPrayers} />
      </section>
    </Flex>
  )
}

export default React.memo(Home)