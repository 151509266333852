import React , { useRef } from 'react'
import { Link , navigate} from '@reach/router'
import { format } from 'date-fns'
import { useFormState } from 'react-use-form-state'
import isEmpty from 'lodash/isEmpty'
import { Button } from 'pcln-design-system'

import useAppState from '../utils/useAppState'

export default function Prayer({ prayerId }) {
  const { createPrayer } = useAppState()
  const [formState, { text, textarea, checkbox }] = useFormState()

  function handleSubmit (e) {
    e.preventDefault()
    if (isEmpty(formState.errors)) {
      createPrayer(formState.values)
      navigate('/')
    }
  }

  return (
    <section>
      <form onSubmit={handleSubmit}>
        <h3>Add a New Prayer Request</h3>
        <input type="hidden" name="createdDate" value={Date.now()} />

        <div className="fieldset">
          <label htmlFor="title">Title</label>
          <input {...text('title')} />
        </div>


        <div className="fieldset">
          <label htmlFor="description">
            <span>Description:</span>
          </label>
          <textarea {...textarea('description')} />
        </div>
        
        <p>
          <label htmlFor="isPublic">
            <input {...checkbox('meta', 'isPublic')} />
            <span>is public?</span>
          </label>

          <br />
          <label htmlFor="isPinned">
            <input {...checkbox('meta', 'isPinned')} />
            <span>is pinned?</span>
          </label>
        </p>
        <Button size="medium" type="submit">Submit</Button>
      </form>
      
      <Link to="/">{'<- '}Home</Link>
    </section>
  )
}