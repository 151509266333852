// import firebase from 'firebase/app'
import firebase from 'firebase'
import 'firebase/firestore'

const config = {
  apiKey: "AIzaSyDoGO6j6Cg6PuEfjaayHyXQzNKsp-iYpkg",
  authDomain: "prayerfire-15ea2.firebaseapp.com",
  databaseURL: "https://prayerfire-15ea2.firebaseio.com",
  projectId: "prayerfire-15ea2",
  storageBucket: "prayerfire-15ea2.appspot.com",
  messagingSenderId: "522915402714"
};
firebase.initializeApp(config);

if (process.env.NODE_ENV === 'development') {
  window.firebase = firebase
}

export default firebase
export const firestore = firebase.firestore()
export const auth = firebase.auth()
