import React from 'react'
import { Link } from '@reach/router'

export default function PrayerList({ prayers }) {
  return (
    <ul className="prayer-list">
      {
        prayers.map(prayer => <Prayer key={prayer.id} {...prayer} />)
      }
    </ul>
  )
}

function Prayer ({ id, title, createdDate }) {
  return (
    <li key={id}>
      <Link to={`/prayers/${id}`}>{title}</Link>
      <br />
      <span className="prayer-date">
        { (new Date(createdDate)).toDateString() }
      </span>
    </li>
  )
}