import React, { useEffect, useState } from 'react'
import { Link } from '@reach/router'
import { Flex } from 'pcln-design-system'

import useAppState from '../utils/useAppState'
import PrayerList from '../components/PrayerList'
import Prayer from './EditPrayer';

export default ({prayerType, prayers}) => {
  const [filteredPrayers = [], setPrayers] = useState([])
  const { getFilteredPrayers } = useAppState()

  useEffect(() => {
    getFilteredPrayers(prayerType).then(setPrayers)
  }, [filteredPrayers.length])

  return (
    <>
      <Flex alignItems='center' justifyContent='space-between'>
        <h2>{prayerType} prayers</h2>
        <Link to='/'>Home</Link>
      </Flex>
      
      <PrayerList prayers={filteredPrayers} />
    </>
  )
}