import { useState, useEffect } from 'react'
import firebase, { firestore, auth  } from './firebase'

const googleProvider = new firebase.auth.GoogleAuthProvider()
let prayerMap = {}
let currentUser = null
window.brookes = prayerMap

export default function useAppState() {
  const [prayers, setPrayers] = useState([])
  const prayerDocs = firestore.collection('prayers')


  // Firestore auto updates
  useEffect(() => {
    const unsubscribe = prayerDocs.orderBy('createdDate').onSnapshot(snapshot => {
      if (snapshot.empty) {
        setPrayers([])
        prayerMap = {}
        return
      }
      const newList = []
      snapshot.forEach(doc => {
        const currentDoc = {
          id: doc.id,
          ...doc.data()
        }
        prayerMap[doc.id] = currentDoc
        newList.push(currentDoc)
      })
      setPrayers(newList)
      return
    })

    return unsubscribe
  }, [])

  // Auth
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      currentUser = user
    })
  }, [currentUser])

  /* CRUD & UTIL functions */
  const getPrayer = id => prayerMap[id]

  function getFilteredPrayers (filter) {
    let whereClause
    
    switch(filter) {
      case 'pinned':
        whereClause = ['isPinned', '==', true]
        break
      case 'public':
        whereClause = ['isPrivate', '==', false]
        break
      case 'closed':
        whereClause = ['isClosed', '==', true]
        break
      case 'private':
        whereClause = ['isPrivate', '==', true]
        break
      case 'user':
      default:
        whereClause = ['uid', '==', currentUser && currentUser.uid]
    }
    
    return prayerDocs.where(...whereClause).get()
    .then(snapShot => {
      const prayers = []
      snapShot.forEach(doc => {
        prayers.push({id: doc.id, ...doc.data()})
      })
      return prayers
    })
  }

  function createPrayer(data) {
    const {title, description, meta: incomingMeta} = data
    const meta = {
      isPinned: meta.includes('isPinned'),
      isPrivate: meta.includes('isPrivate'),
      isClosed: meta.includes('isClosed'),
    }
    const prayer = { title, description, createdDate: Date.now(), uid: currentUser.uid, ...meta }

    prayerDocs.add(prayer)
  }

  function editPrayer(id, data) {
    const {title, description, meta, uid } = data
    const docUpdate = {
      title,
      description,
      isPinned: meta.includes('isPinned'),
      isPrivate: meta.includes('isPrivate'),
      isClosed: meta.includes('isClosed'),
    }

    if (!uid) {
      docUpdate.uid = currentUser.uid
    }
    prayerDocs.doc(id).update(docUpdate)
  }

  function signIn() {
    auth.signInWithPopup(googleProvider)
    .then(console.log)
  }

  function signOut() {
    auth.signOut()
    .then(console.log)
  }

  return { prayers, getPrayer, getFilteredPrayers, createPrayer, editPrayer, currentUser, signIn, signOut }
}