import React, { useState, useEffect } from 'react'
import { Router, Link } from '@reach/router'

import './styles/index'

import useAppState from './utils/useAppState'
import ListTypeScreen from './screens/ListType'
import HomeScreen from './screens/Home'
import PrayerScreen from './screens/Prayer'
import NewPrayerScreen from './screens/NewPrayer'
import EditPrayerScreen from './screens/EditPrayer'

export default function App() {

  const { prayers, currentUser, signIn, signOut } = useAppState()

  return (
    <React.Fragment>
      <header id="header">
        <h3><Link to="/">PrayerBox</Link></h3>
        <Link className="action" to="/prayers/new">+ Add Prayer Request</Link>
      </header>
      <main id="app-main">
        <Router>
          <HomeScreen path="/" prayers={prayers} />
          <NewPrayerScreen path="/prayers/new" />
          <EditPrayerScreen path="/edit/:prayerId" />
          <PrayerScreen path="/prayers/:prayerId" />
          <ListTypeScreen path="/prayerlist/:prayerType" />
        </Router>
      </main>
      <footer id="footer">
        {
          currentUser ?
          (
            <React.Fragment>
                <a className="action" onClick={signOut}>log out</a> {currentUser.displayName}
            </React.Fragment>
          )
          : <a onClick={signIn}>log in</a>
        }
      </footer>
    </React.Fragment>
  )
}